// Define Project Colors
$c-primary: rgba(188, 0, 0, 1);
$c-secondary: black;
$c-background: white;
$c-light-text: white;
$c-standardText: black;



@mixin desktop() {
    @media (min-width: 960px) {
        @content;
    }
}

* {
  box-sizing: border-box;
}

body {
  font-size: 1.1em;
}

.desktop {
  display: none;
  @include desktop { display: flex;}
}

.mobile {
  display: flex;
  @include desktop { display: none; }
}

.anchor {
  width: 5px;
  height: 5px;
  position: absolute;
  top: -100px;
}

button {
  font-size: 1.5em;
  font-weight: bold;
  font-family: sans-serif;
  background: $c-primary;
  color: $c-light-text;
  padding: 8px;
  padding-top: 11px;
  padding-bottom: 5px;
  width: 200px;
  border-radius: 30px;
  border: none;
}
button:hover {
  cursor: pointer;
}

.card {
  border-radius: 10px;
  overflow: hidden;
  margin: 10px;
  box-shadow: 10px 10px 31px -4px rgba(0,0,0,0.55);
  .top {
    position: relative;
    padding: 1px;
    background-color: $c-primary;
    text-align: center;
    h2 {
      color: $c-light-text;
    }
  }
  .bottom {
    ul {
      padding-left: 25px;
      padding-right: 15px;
    }
  }
}

.payment {
  display: none;
}

.underline {
  width: 100%;
  height: 5px;
  background-color: rgba(188, 0, 0, 1);
}

.hamburger-wrapper {
  display: inline-block;
  width: 100vw;
  float: right;
  @include desktop {
    display: none;
  }
}

.hamburger-menu {
  height: 50px;
  float: left;
}

.hamburger {
  width: auto;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  background-color: $c-background;
}

section {
  position: relative;
}

nav {
  width: 100vw;
  height: 50px;
  position: fixed;
  top: 0;
  background: $c-secondary;
  z-index: 1000;
  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  li {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-top: 12px;
    a {
      font-size: 1.3em;
      text-decoration: none;
      color: $c-light-text;
      font-weight: bold;
    }
  }
  .donate-button {
    padding: 0;
  }
  button {
    font-size: 1.3em;
    border-radius: 0;
    height: 50px;
  }
}

#mobile-nav {
  height: auto;
  position: fixed;
  flex-direction: column;
  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100vw;
    .donate {
      height: 50px;
    }
  }
  .bottom {
    width: 100vw;
    .nav-items {
      display: flex;
      flex-direction: column;
      display: none;
      padding-bottom: 12px;
      li {
        text-align: center;
        padding: 12px;
      }
      a {
        padding: 20px 0;
        width: 100%;
      }
      p {
        margin: 0;
      }
      .spacer {
        height: 50px;
      }
    }
  }
}

.jumbotron {
  position: fixed;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 50px;
  top: 0;
  z-index: -10;
  width: 100%;
  .lhs {
    .image-wrapper {
      width: 33vw;
      max-width: 305px;
      img {
        width: 100%;
        border-right: 7px solid $c-primary;
      }
    }
  }
  .rhs {
    z-index: -6;
    width: 100%;
    height: 400px;
    max-height: 44vw;
    position: relative;
    .cheryl-logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      img {
        max-width: 50vw;
      }
    }
    .gradient {
      position: absolute;
      top: 0;
      right: 0;
      background: linear-gradient(90deg, rgba(255,255,255,1) 23%, rgba(255,255,255,0.7385153890657825) 65%, rgba(255,255,255,0.22591034704897583) 100%);
      width: 75%;
      height: 100%;
      z-index: -4;
    }
    .flag {
      position: absolute;
      top: 0;
      right: 0;
      background-image: url("../../media/flag.jpg");
      width: 75%;
      height: 100%;
      background-size: cover;
      z-index: -5;
    }
  }
}

.main {
  margin-top: 49vw;
  z-index: 10;
  min-height: 100vh;
  background: $c-background;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 0;
  @include desktop {
    margin-top: 444px;
  }
  .shadow {
    position: absolute;
    width: 100%;
    height: 1px;
    box-shadow: 0px 26px 13px 21px rgba(0,0,0,0.64);
    z-index: -1;
    top: 0;
  }

  .star-points {
    width: 100%;
    border-bottom: 5px solid $c-secondary;
    background: $c-background;
    padding: 1px;
    overflow: hidden;
    ul {
      padding: 0;
      margin: 10px 0;
      margin-left: 50%;
      list-style: none;
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: left;
      transform: translateX(-25%);
      @include desktop {
        transform: translateX(0);
        margin: 0;
        flex-direction: row;
        justify-content: space-around;
      }
    }
    h2 {
      margin: 5px 0;
    }
  }

  #promises {
    max-width: 90vw;
    margin: 0 auto;
    margin-top: 75px;
    padding: 0 10px;
    @include desktop {
      max-width: 1500px;
    }
    .cards {
      width: 100%;
      display: flex;
      flex-direction: column;
      @include desktop {
        flex-direction: row;
      }
    }
  }

  #about {
    max-width: 1500px;
    margin: 0 auto;
    margin-top: 75px;
    padding: 0 10px;
    .opening {
      margin-bottom: 30px;
    }
    ul {
      list-style: none;
      position: relative;
      li {
        margin-bottom: 20px;
      }
      li:before {
        content: '';
        height: 30px;
        width: 30px;
        position: absolute;
        background-image: url("../../media/gop_logo.png");
        background-size: cover;
        background-position: center;
        left: 0;
        // top: 50%;
        // transform: translateY(-50%);
      }
    }
    .closing {
      margin-top: 30px;
    }
  }

  #experience {
    display: flex;
    flex-direction: column;
    max-width: 1500px;
    margin: 0 auto;
    justify-content: space-around;
    @include desktop {
      flex-direction: row;
    }
    .card {
      width: 100%;
      margin: 0 auto;
      margin-top: 25px;
      max-width: 90vw;
    }
    .lhs, .rhs {
      max-width: 600px;
      margin: 0 auto;
      padding: 0 10px;
    }
    .lhs {}
    .rhs {
      .endorsement {
        border: 7px solid $c-primary;
        padding: 0 20px;
        width: 100%;
        margin: 0 auto;
        margin-top: 70px;
        max-width: 90vw;
      }
    }
  }

  #taxpayer-advocacy {
    max-width: 90vw;
    margin: 0 auto;
    margin-top: 75px;
    padding: 0 10px;
    @include desktop {
      max-width: 1500px;
    }
    .subtitle {
      font-weight: normal;
    }
    ul {
      list-style: none;
      padding: 0;
      h2 {
        color: $c-primary;
      }
    }
    .container {
      display: flex;
      flex-direction: row;
      img {
        padding-top: 20px;
        padding-right: 20px;
        width: 120px;
        max-width: 15vw;
      }
    }
  }

  #get-involved {
    width: 100%;
    max-width: 90vw;
    margin: 0 auto;
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    @include desktop {
      max-width: 1500px;
    }
    .signup-section {
      width: 100%;
      max-width: 700px;
      margin: 0 auto;
      position: relative;
      form {
        display: flex;
        flex-direction: column;
        label {
          font-size: 1.2em;
          padding-top: 15px;
        }
      }
      .button-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
      #signup-button {
        margin-top: 50px;
      }
    }
    .contact {
      margin-top: 75px;
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      div {
        display: flex;
        flex-direction: column;
      }
      .social-media {
        display: flex;
        justify-content: center;
        flex-direction: row;
        img {
          width: 50px;
        }
      }
      a {
        padding: 5px;
        color: $c-primary;
      }
    }
    .signup-wrapper {
      background: $c-background;
    }
    .thank-you {
      text-align: center;
      top: 0;
      display: none;
      transition: display 2s, opacity 2s;
    }
    .legal {
      padding-top: 20px;
      text-align: center;
    }
  }

}

// footer {
//   height: 100px;
//   width: 100%;
//   background-image: url("../../media/linen.png");
//   background-repeat: repeat;
//   position: relative;
//   bottom: 0;
//   z-index: -5;
//   display: none;
//   justify-content: center;
//   font-size: 0.75em;
//   @include desktop {
//     font-size: 1em;
//   }
//   .legal {
//     text-align: center;
//     color: rgba(59%,59%,59%,1);
//     bottom: 0;
//     position: absolute;
//     text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
//   }
// }
